<template>
    <modal ref="modalVerCategoria" titulo="Inventario por valor de categorías y numero de productos" no-aceptar no-cancelar>
        <div v-for="(data,d) in categorias" :key="d" class="row mx-0 mb-3">
            <div class="col-auto" />
            <div class="col">
                <p class="f-15 text-general mb-2">
                    {{ data.nombre }}
                </p>
                <div class="row mx-0">
                    <div class="col-auto text-general2 pl-0">
                        {{ separadorNumero(data.total) }}
                    </div>
                    <div class="col-auto text-general">
                        {{ agregarSeparadoresNumero(data.cantidad) }} productos
                    </div>
                </div>
                <div class="row mx-0" style="height:10px;">
                    <div class="h-100 bg-gris br-20" :style="`width:${porcentajesBordes(data.porcentaje_max_total)}%;`" />
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            categorias: []
        }
    },
    methods: {
        toggle(data){
            this.categorias = data
            this.$refs.modalVerCategoria.toggle();
        }
    }
}
</script>
